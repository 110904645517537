import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { height } from '@mui/system';

export default function Loading({light = false}) {

  return (
    <Box style={{ display: 'block', position: 'absolute', top: 'calc(50% - 90px)', left: 'calc(50% - 11px)' }}>
      <Box sx={{ display: 'flex', alignSelf: 'center' }}>
            <CircularProgress className={`loadingSpinner${light ? ' light' : ''}`} />
      </Box>
    </Box>
  )
}
