import React from 'react';
import Button from '@mui/material/Button';
import { Close } from '../utils/icons';

export default function CloseIcon({handleClose, label = ""}) {

  return (
    <Button
        className='close-icon-btn'
        onClick={handleClose}
        aria-label={label}
    >
        <Close />
    </Button>
  )
}
