import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getAppData } from './service-offline-data';
import logo from './images/of-logo.png';
import './styles/App.scss';
import MainView from './pages/MainView';

function App() {

  const serviceWorkerRegistration = useSelector(
    state => state.serviceWorkerRegistration,
  );

  useEffect(() => {
    getAppData()
    setTimeout(() => {setIsLoading(false)}, 7000)
    if (navigator.onLine) {
      setIsOffline(false)
    } else {
      setIsOffline(true)
    }
  
    window.addEventListener('offline', function(e) { setIsOffline(true); });
    window.addEventListener('online', function(e) { setIsOffline(false); });

    setIsIOS(iOS())

    setUpdatesWaiting(serviceWorkerRegistration && serviceWorkerRegistration.waiting || false)

    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDefferedPrompt(e);
    });

   }, []);

   useEffect(() => {
    setUpdatesWaiting(serviceWorkerRegistration && serviceWorkerRegistration.waiting || false)
   }, [serviceWorkerRegistration]);

   function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  const [isLoading , setIsLoading] = useState(true)
  const [isOffline, setIsOffline] = useState(false)
  const [isIOS, setIsIOS] = useState(false)
  const [updatesWaiting, setUpdatesWaiting] = useState(false)
  const [defferedPrompt, setDefferedPrompt] = useState(null)

  return (
    <div className="App">
      {isLoading ?
        <header className="App-header" style={{background: process.env.REACT_APP_PRIMARY_BG}} >
          <div className={`App-logo home-image-header ${process.env.REACT_APP_APP_LOGO && process.env.REACT_APP_SHOW_OF_LOGO === 'true' ? 'with-brand-image' : 'no-brand-image'}`}>
            {process.env.REACT_APP_APP_LOGO && 
              <div><img src={process.env.REACT_APP_APP_LOGO} alt={process.env.REACT_APP_APP_NAME} /></div>
            }
            {process.env.REACT_APP_SHOW_OF_LOGO === 'true' &&
              <div><img src={logo} alt="The Optical Foundry" /></div>
            }
          </div>
        </header>
      :
        <> 
          <MainView isOffline={isOffline} isIOS={isIOS} updatesWaiting={updatesWaiting} defferedPrompt={defferedPrompt} setDefferedPrompt={setDefferedPrompt} />
        </>
      }
    </div>
  );
}

export default App;
