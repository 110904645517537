import React from 'react'

export const Add = () => {

    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="18" cy="18" r="18" fill="white"/>
        <path d="M18.0001 13.2V22.8" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22.8002 18H13.2002" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M18.0002 28.8V28.8C12.035 28.8 7.2002 23.9652 7.2002 18V18C7.2002 12.0348 12.035 7.2 18.0002 7.2V7.2C23.9654 7.2 28.8002 12.0348 28.8002 18V18C28.8002 23.9652 23.9654 28.8 18.0002 28.8Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export const BackArrow = () => {
    return (
        <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 1L2 9L10 17" stroke="black" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    )
}

export const Close = () => {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L14 14" stroke="white" strokeWidth="2" strokeLinecap="round"/>
        <path d="M1 14L14 1" stroke="white" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    )
}

export const Delete = () => {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.2 7.8V22.8C22.2 24.1255 21.1255 25.2 19.8 25.2H8.99998C7.67449 25.2 6.59998 24.1255 6.59998 22.8V7.8" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.2 7.80001V5.40001C10.2 4.40589 11.0059 3.60001 12 3.60001H16.8C17.7941 3.60001 18.6 4.40589 18.6 5.40001V7.80001" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M24 7.8H4.79999" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const Edit = () => {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.3516 18.7428L18.7428 6.3516C19.2108 5.8836 19.9704 5.8836 20.4384 6.3516L22.4496 8.3628C22.9176 8.8308 22.9176 9.5904 22.4496 10.0584L10.0572 22.4484C9.8328 22.674 9.528 22.8 9.21 22.8H6V19.59C6 19.272 6.126 18.9672 6.3516 18.7428Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.5 8.592L20.208 12.3" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const Home = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 8.6L11.8566 3.10036C11.9427 3.0401 12.0573 3.0401 12.1434 3.10036L20 8.6V20.75C20 20.8881 19.8881 21 19.75 21H15.25C15.1119 21 15 20.8881 15 20.75V15C15 13.8954 14.1046 13 13 13H11C9.89543 13 9 13.8954 9 15V20.75C9 20.8881 8.88807 21 8.75 21H4.25C4.11193 21 4 20.8881 4 20.75V8.6Z" fill="white"/>
        <path d="M11.8566 3.10036L11.2832 2.28112L11.2832 2.28112L11.8566 3.10036ZM4 8.6L3.42654 7.78077L3 8.07934V8.6H4ZM20 8.6H21V8.07934L20.5735 7.78077L20 8.6ZM12.1434 3.10036L12.7168 2.28112L12.7168 2.28112L12.1434 3.10036ZM11.2832 2.28112L3.42654 7.78077L4.57346 9.41923L12.4301 3.91959L11.2832 2.28112ZM3 8.6V20.75H5V8.6H3ZM4.25 22H8.75V20H4.25V22ZM10 20.75V15H8V20.75H10ZM11 14H13V12H11V14ZM14 15V20.75H16V15H14ZM15.25 22H19.75V20H15.25V22ZM21 20.75V8.6H19V20.75H21ZM20.5735 7.78077L12.7168 2.28112L11.5699 3.91959L19.4265 9.41923L20.5735 7.78077ZM19.75 22C20.4404 22 21 21.4404 21 20.75H19C19 20.3358 19.3358 20 19.75 20V22ZM14 20.75C14 21.4404 14.5596 22 15.25 22V20C15.6642 20 16 20.3358 16 20.75H14ZM10 15C10 14.4477 10.4477 14 11 14V12C9.34315 12 8 13.3431 8 15H10ZM8.75 22C9.44036 22 10 21.4404 10 20.75H8C8 20.3358 8.33579 20 8.75 20V22ZM3 20.75C3 21.4404 3.55964 22 4.25 22V20C4.66421 20 5 20.3358 5 20.75H3ZM13 14C13.5523 14 14 14.4477 14 15H16C16 13.3431 14.6569 12 13 12V14ZM12.4301 3.91959C12.1719 4.10036 11.8281 4.10036 11.5699 3.91959L12.7168 2.28112C12.2864 1.97984 11.7136 1.97984 11.2832 2.28112L12.4301 3.91959Z" fill="white"/>
        <path d="M22 10L12.1434 3.10036C12.0573 3.0401 11.9427 3.0401 11.8566 3.10036L2 10" stroke="white" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    )
}

export const MenuCollectionActive = () => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.4993 15.0008C9.17615 13.6669 6.31941 13.6669 3.99622 15.0008" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.9983 6.3994L8.1017 6.10053C7.56428 5.92139 6.97612 5.97846 6.48317 6.25756C5.99021 6.53667 5.63868 7.01165 5.51579 7.56465L4.09151 13.9739C4.02817 14.2589 3.99622 14.55 3.99622 14.842V17.0017C3.99622 18.6592 5.33992 20.0029 6.99747 20.0029H8.283C9.8249 20.0029 11.1159 18.8345 11.2694 17.3003L11.4993 15.0008" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.0017 6.3994L17.8983 6.10053C18.4357 5.92139 19.0238 5.97846 19.5168 6.25756C20.0097 6.53667 20.3613 7.01165 20.4842 7.56465L21.9084 13.9739C21.9718 14.2589 22.0037 14.55 22.0037 14.842V17.0017C22.0037 18.6592 20.66 20.0029 19.0025 20.0029H17.7169C16.1751 20.0029 14.884 18.8345 14.7306 17.3003L14.5006 15.0008" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.4994 15.0008C12.3886 14.3339 13.6114 14.3339 14.5006 15.0008" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22.0037 15.0008C19.6805 13.6669 16.8238 13.6669 14.5006 15.0008" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.3604 14.9318C8.31892 13.4795 5.36275 14.2822 4.12688 14.9306C4.04744 14.9723 4 15.0554 4 15.1451V17.4692C4 17.4897 4.00261 17.5105 4.00766 17.5303C4.51018 19.5051 5.51016 20 7.5 20C9.5 20 10.5 19.5 11 18.5C11.3769 17.7462 11.4875 16.0452 11.499 15.1542C11.5002 15.0595 11.4459 14.9726 11.3604 14.9318Z" fill="white"/>
        <path d="M14.6396 14.9318C17.6811 13.4795 20.6373 14.2822 21.8731 14.9306C21.9526 14.9723 22 15.0554 22 15.1451V17.4692C22 17.4897 21.9974 17.5105 21.9923 17.5303C21.4898 19.5051 20.4898 20 18.5 20C16.5 20 15.5 19.5 15 18.5C14.6231 17.7462 14.5125 16.0452 14.501 15.1542C14.4998 15.0595 14.5541 14.9726 14.6396 14.9318Z" fill="white"/>
        </svg>
    )
}

export const MenuCollectionInactive = () => {
    return (
        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.4993 14.0109C9.17615 12.677 6.31941 12.677 3.99622 14.0109" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.9983 5.40943L8.1017 5.11055C7.56428 4.93142 6.97612 4.98848 6.48317 5.26759C5.99021 5.54669 5.63868 6.02168 5.51579 6.57467L4.09151 12.9839C4.02817 13.269 3.99622 13.5601 3.99622 13.852V16.0117C3.99622 17.6692 5.33992 19.0129 6.99747 19.0129H8.283C9.8249 19.0129 11.1159 17.8446 11.2694 16.3103L11.4993 14.0109" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.0017 5.40943L17.8983 5.11055C18.4357 4.93142 19.0238 4.98848 19.5168 5.26759C20.0097 5.54669 20.3613 6.02168 20.4842 6.57467L21.9084 12.9839C21.9718 13.269 22.0037 13.5601 22.0037 13.852V16.0117C22.0037 17.6692 20.66 19.0129 19.0025 19.0129H17.7169C16.1751 19.0129 14.884 17.8446 14.7306 16.3103L14.5006 14.0109" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.4994 14.0108C12.3886 13.3439 13.6114 13.3439 14.5006 14.0108" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22.0037 14.0109C19.6805 12.677 16.8238 12.677 14.5006 14.0109" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const MenuHomeActive = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 8.6L11.8566 3.10036C11.9427 3.0401 12.0573 3.0401 12.1434 3.10036L20 8.6V20.75C20 20.8881 19.8881 21 19.75 21H15.25C15.1119 21 15 20.8881 15 20.75V15C15 13.8954 14.1046 13 13 13H11C9.89543 13 9 13.8954 9 15V20.75C9 20.8881 8.88807 21 8.75 21H4.25C4.11193 21 4 20.8881 4 20.75V8.6Z" fill="white"/>
        <path d="M11.8566 3.10036L11.2832 2.28112L11.2832 2.28112L11.8566 3.10036ZM4 8.6L3.42654 7.78077L3 8.07934V8.6H4ZM20 8.6H21V8.07934L20.5735 7.78077L20 8.6ZM12.1434 3.10036L12.7168 2.28112L12.7168 2.28112L12.1434 3.10036ZM11.2832 2.28112L3.42654 7.78077L4.57346 9.41923L12.4301 3.91959L11.2832 2.28112ZM3 8.6V20.75H5V8.6H3ZM4.25 22H8.75V20H4.25V22ZM10 20.75V15H8V20.75H10ZM11 14H13V12H11V14ZM14 15V20.75H16V15H14ZM15.25 22H19.75V20H15.25V22ZM21 20.75V8.6H19V20.75H21ZM20.5735 7.78077L12.7168 2.28112L11.5699 3.91959L19.4265 9.41923L20.5735 7.78077ZM19.75 22C20.4404 22 21 21.4404 21 20.75H19C19 20.3358 19.3358 20 19.75 20V22ZM14 20.75C14 21.4404 14.5596 22 15.25 22V20C15.6642 20 16 20.3358 16 20.75H14ZM10 15C10 14.4477 10.4477 14 11 14V12C9.34315 12 8 13.3431 8 15H10ZM8.75 22C9.44036 22 10 21.4404 10 20.75H8C8 20.3358 8.33579 20 8.75 20V22ZM3 20.75C3 21.4404 3.55964 22 4.25 22V20C4.66421 20 5 20.3358 5 20.75H3ZM13 14C13.5523 14 14 14.4477 14 15H16C16 13.3431 14.6569 12 13 12V14ZM12.4301 3.91959C12.1719 4.10036 11.8281 4.10036 11.5699 3.91959L12.7168 2.28112C12.2864 1.97984 11.7136 1.97984 11.2832 2.28112L12.4301 3.91959Z" fill="white"/>
        <path d="M22 10L12.1434 3.10036C12.0573 3.0401 11.9427 3.0401 11.8566 3.10036L2 10" stroke="white" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    )
}

export const MenuHomeInactive = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 8.6L3.42654 7.78077L3 8.07934V8.6H4ZM20 8.6H21V8.07934L20.5735 7.78077L20 8.6ZM11.8566 3.10036L12.4301 3.91959L11.8566 3.10036ZM12.1434 3.10036L11.5699 3.91959L12.1434 3.10036ZM11.2832 2.28112L3.42654 7.78077L4.57346 9.41923L12.4301 3.91959L11.2832 2.28112ZM3 8.6V20.75H5V8.6H3ZM4.25 22H8.75V20H4.25V22ZM10 20.75V15H8V20.75H10ZM11 14H13V12H11V14ZM14 15V20.75H16V15H14ZM15.25 22H19.75V20H15.25V22ZM21 20.75V8.6H19V20.75H21ZM20.5735 7.78077L12.7168 2.28112L11.5699 3.91959L19.4265 9.41923L20.5735 7.78077ZM19.75 22C20.4404 22 21 21.4404 21 20.75H19C19 20.3358 19.3358 20 19.75 20V22ZM14 20.75C14 21.4404 14.5596 22 15.25 22V20C15.6642 20 16 20.3358 16 20.75H14ZM10 15C10 14.4477 10.4477 14 11 14V12C9.34315 12 8 13.3431 8 15H10ZM8.75 22C9.44036 22 10 21.4404 10 20.75H8C8 20.3358 8.33579 20 8.75 20V22ZM3 20.75C3 21.4404 3.55965 22 4.25 22V20C4.66421 20 5 20.3358 5 20.75H3ZM13 14C13.5523 14 14 14.4477 14 15H16C16 13.3431 14.6569 12 13 12V14ZM12.4301 3.91959C12.1719 4.10036 11.8281 4.10036 11.5699 3.91959L12.7168 2.28112C12.2864 1.97984 11.7136 1.97984 11.2832 2.28112L12.4301 3.91959Z" fill="white"/>
        <path d="M22 10L12.1434 3.10036C12.0573 3.0401 11.9427 3.0401 11.8566 3.10036L2 10" stroke="white" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    )
}

export const MenuProfileActive = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.4247 6.57538C25.5252 10.6759 25.5252 17.3241 21.4247 21.4246C17.3242 25.5251 10.676 25.5251 6.5755 21.4246C2.475 17.3241 2.475 10.6759 6.5755 6.57538C10.676 2.47487 17.3242 2.47487 21.4247 6.57538" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20.7423 22.0501C16.8512 25.3166 11.1487 25.3166 7.25769 22.0501C11.0558 18.3166 16.9443 18.3166 20.7423 22.0501Z" fill="white" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M16.3206 9.71122C17.6022 10.9929 17.6022 13.0708 16.3206 14.3524C15.0389 15.6341 12.961 15.6341 11.6794 14.3524C10.3977 13.0708 10.3977 10.9929 11.6794 9.71122C12.961 8.42959 15.0389 8.42959 16.3206 9.71122Z" fill="white"/>
        <path d="M16.3206 9.71122C17.6022 10.9929 17.6022 13.0708 16.3206 14.3524C15.0389 15.6341 12.961 15.6341 11.6794 14.3524C10.3977 13.0708 10.3977 10.9929 11.6794 9.71122C12.961 8.42959 15.0389 8.42959 16.3206 9.71122" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const MenuProfileInactive = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.4247 6.57538C25.5252 10.6759 25.5252 17.3241 21.4247 21.4246C17.3242 25.5251 10.676 25.5251 6.5755 21.4246C2.475 17.3241 2.475 10.6759 6.5755 6.57538C10.676 2.47487 17.3242 2.47487 21.4247 6.57538" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20.7423 22.0501C16.8512 25.3166 11.1487 25.3166 7.25769 22.0501C11.0558 18.3166 16.9443 18.3166 20.7423 22.0501Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.3206 9.71122C17.6022 10.9929 17.6022 13.0708 16.3206 14.3524C15.0389 15.6341 12.961 15.6341 11.6794 14.3524C10.3977 13.0708 10.3977 10.9929 11.6794 9.71122C12.961 8.42959 15.0389 8.42959 16.3206 9.71122" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const MenuSearchActive = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M22.0896 13.3782V13.3782C22.0896 18.1895 18.1895 22.0897 13.3781 22.0897V22.0897C8.56679 22.0897 4.66663 18.1895 4.66663 13.3782V13.3782C4.66663 8.56684 8.56679 4.66667 13.3781 4.66667V4.66667C18.1895 4.66667 22.0896 8.56684 22.0896 13.3782Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M18.585 13.3758C18.585 16.2528 16.2528 18.585 13.3758 18.585C10.4988 18.585 8.16663 16.2528 8.16663 13.3758C8.16663 10.4988 10.4988 8.16667 13.3758 8.16667C16.2528 8.16667 18.585 10.4988 18.585 13.3758Z" fill="white"/>
        <path d="M24.5 24.5L19.5416 19.5417" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const MenuSearchInactive = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M22.0896 13.3782V13.3782C22.0896 18.1895 18.1895 22.0897 13.3781 22.0897V22.0897C8.56679 22.0897 4.66663 18.1895 4.66663 13.3782V13.3782C4.66663 8.56682 8.56679 4.66666 13.3781 4.66666V4.66666C18.1895 4.66666 22.0896 8.56682 22.0896 13.3782Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M24.5 24.5L19.5416 19.5417" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const Remove = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#F8F8F8"/>
        <path d="M8 7.33333L16.6667 16" stroke="black" strokeWidth="2" strokeLinecap="round"/>
        <path d="M8 16L16.6667 7.33333" stroke="black" strokeWidth="2" strokeLinecap="round"/>
        </svg>         
    )
}

export const Save = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15" r="15" fill="white"/>
        <path d="M22.0569 9.25804C21.6583 8.8592 21.1849 8.54282 20.664 8.32696C20.143 8.1111 19.5847 8 19.0208 8C18.4569 8 17.8985 8.1111 17.3775 8.32696C16.8566 8.54282 16.3832 8.8592 15.9846 9.25804L15.1573 10.0854L14.3299 9.25804C13.5247 8.4528 12.4326 8.00042 11.2938 8.00042C10.155 8.00042 9.06286 8.4528 8.25762 9.25804C7.45238 10.0633 7 11.1554 7 12.2942C7 13.433 7.45238 14.5251 8.25762 15.3304L9.08495 16.1577L15.1573 22.23L21.2296 16.1577L22.0569 15.3304C22.4558 14.9317 22.7721 14.4584 22.988 13.9374C23.2038 13.4165 23.315 12.8581 23.315 12.2942C23.315 11.7303 23.2038 11.1719 22.988 10.651C22.7721 10.13 22.4558 9.65668 22.0569 9.25804V9.25804Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const Saved = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15" r="15" fill="white"/>
        <path d="M22.0569 9.25804C21.6583 8.8592 21.1849 8.54282 20.664 8.32696C20.143 8.1111 19.5847 8 19.0208 8C18.4569 8 17.8985 8.1111 17.3775 8.32696C16.8566 8.54282 16.3832 8.8592 15.9846 9.25804L15.1573 10.0854L14.3299 9.25804C13.5247 8.4528 12.4326 8.00042 11.2938 8.00042C10.155 8.00042 9.06286 8.4528 8.25762 9.25804C7.45238 10.0633 7 11.1554 7 12.2942C7 13.433 7.45238 14.5251 8.25762 15.3304L9.08495 16.1577L15.1573 22.23L21.2296 16.1577L22.0569 15.3304C22.4558 14.9317 22.7721 14.4584 22.988 13.9374C23.2038 13.4165 23.315 12.8581 23.315 12.2942C23.315 11.7303 23.2038 11.1719 22.988 10.651C22.7721 10.13 22.4558 9.65668 22.0569 9.25804Z" fill="black" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>         
    )
}

export const Search = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M22.0897 13.3782V13.3782C22.0897 18.1895 18.1896 22.0897 13.3782 22.0897V22.0897C8.56691 22.0897 4.66675 18.1895 4.66675 13.3782V13.3782C4.66675 8.56682 8.56691 4.66666 13.3782 4.66666V4.66666C18.1896 4.66666 22.0897 8.56682 22.0897 13.3782Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M24.5001 24.5L19.5417 19.5417" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const Settings = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.2097 12.7903C18.4301 14.0107 18.4301 15.9893 17.2097 17.2097C15.9893 18.4301 14.0107 18.4301 12.7903 17.2097C11.5699 15.9893 11.5699 14.0106 12.7903 12.7903C14.0107 11.5699 15.9893 11.5699 17.2097 12.7903" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M18.5612 5.15L19.2899 5.3925C19.9574 5.615 20.4087 6.24 20.4087 6.94375V7.99C20.4087 8.88 21.1187 9.60625 22.0087 9.625L23.0562 9.6475C23.6624 9.66 24.2124 10.0088 24.4837 10.5513L24.8274 11.2388C25.1424 11.8688 25.0187 12.6288 24.5212 13.1263L23.7812 13.8663C23.1524 14.495 23.1412 15.5113 23.7562 16.1538L24.4812 16.9113C24.9012 17.35 25.0437 17.9838 24.8512 18.56L24.6087 19.2888C24.3862 19.9563 23.7612 20.4075 23.0574 20.4075H22.0112C21.1212 20.4075 20.3949 21.1175 20.3762 22.0075L20.3537 23.055C20.3412 23.6613 19.9924 24.2113 19.4499 24.4825L18.7624 24.8263C18.1324 25.1413 17.3724 25.0175 16.8749 24.52L16.1349 23.78C15.5062 23.1513 14.4899 23.14 13.8474 23.755L13.0899 24.48C12.6512 24.9 12.0174 25.0425 11.4412 24.85L10.7124 24.6075C10.0449 24.385 9.5937 23.76 9.5937 23.0563V22.01C9.5937 21.12 8.8837 20.3938 7.9937 20.375L6.9462 20.3525C6.33995 20.34 5.78995 19.9913 5.5187 19.4488L5.17495 18.7613C4.85995 18.1313 4.9837 17.3713 5.4812 16.8738L6.2212 16.1338C6.84995 15.505 6.8612 14.4888 6.2462 13.8463L5.5212 13.0888C5.09995 12.6488 4.95745 12.0138 5.14995 11.4388L5.39245 10.71C5.61495 10.0425 6.23995 9.59125 6.9437 9.59125H7.98995C8.87995 9.59125 9.6062 8.88125 9.62495 7.99125L9.64745 6.94375C9.66245 6.3375 10.0099 5.7875 10.5524 5.51625L11.2399 5.1725C11.8699 4.8575 12.6299 4.98125 13.1274 5.47875L13.8674 6.21875C14.4962 6.8475 15.5124 6.85875 16.1549 6.24375L16.9124 5.51875C17.3512 5.1 17.9862 4.9575 18.5612 5.15V5.15Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}