import React from 'react';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import logo from '../images/ogi-logo.png';
import CloseIcon from './CloseIcon';
import Button from '@mui/material/Button';
import {BackArrow} from '../utils/icons';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import '../styles/Home.scss';
import DownloadPassword from './settings/download-password';

import { updateAppData, updateVRData } from '../service-offline-data'

export default function Settings({open, handleClose, updatesWaiting}) {

  const [loadingUpdate, setLoadingUpdate] = React.useState(false);
  const [loadingDownload, setLoadingDownload] = React.useState(false);
  const [downloadCount, setDownloadCount] = React.useState(false);
  const [policy, setPolicy] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [download, setDownload] = React.useState(false);

  const viewPolicy = (v) => {
    setPolicy(v)
  }

  const viewUpdate = (v) => {
    setUpdate(v)
  }

  const settingsClose = () => {
    handleClose()
    viewPolicy(false)
    viewUpdate(false)
  }

  const handleDownload = async () => {
    setLoadingDownload(true)
    await updateAppData(downloadCount, setDownloadCount)
    setLoadingDownload(false)
  }

    const serviceWorkerRegistration = useSelector(
        state => state.serviceWorkerRegistration,
      );

    const updateServiceWorker = async () => {
      setLoadingUpdate(true)

      try {
        const registrationWaiting = serviceWorkerRegistration && serviceWorkerRegistration.waiting || false;
        if (registrationWaiting) {
          console.log('Updating App SW')
          registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
          registrationWaiting.addEventListener('statechange', e => {
            if (e.target.state === 'activated') {
              window.location.reload();
            }
          });
        }
        else {
          window.location.reload();
        }
      }
      catch (err) {
        setLoadingUpdate(false)
      }
    };
  
  return (
    <>
      <Dialog
        open={open}
        onClose={settingsClose}
        className='settingsDialog'
        style={{
            backgroundColor: 'transparent',
          }}
      >
            
              <CloseIcon handleClose={settingsClose} label="Close settings" />
            
            {!policy && !update &&
              <Box className="settingsDialogContent">
                <h2>Settings</h2>
                <Box>
                    <h3>Update</h3>
                    <p>Update your app to the newest version available.</p>
                    <LoadingButton
                            variant='outlined'
                            onClick={updateServiceWorker}
                            className='product'
                            loading={loadingUpdate}
                            loadingPosition="start"
                        >{loadingUpdate ? 'Updating...' : 'Update'}</LoadingButton>
                </Box>
                <Box marginTop={'30px'}>
                    <h3>Offline Mode</h3>
                    <p>Download the latest eyewear releases to continue using the app offline.</p>
                    <Button
                            variant='outlined'
                            onClick={(e) => {setDownload(true); handleClose()}}
                            className='product'
                        >Download</Button>
                </Box>
                <Box marginTop={'30px'}>
                    <h3>Contact Information</h3>
                    <p>3971 Quebec Avenue<br />North Minneapolis, MN 55427<br />United States</p>
                    <p>info@theopticalfoundry.com<br />888.560.1060<br />theopticalfoundry.com</p>
                    <p style={{textAlign: "center", marginTop: '25px'}}>
                    <Button onClick={() => viewPolicy(true)} variant='outlined' className='product' >Privacy Policy</Button></p>
                    <p style={{textAlign: "center"}}>© {new Date().getFullYear()} by The Optical Foundry. The Optical Foundry is the Holding company for l.a. Eyeworks, OGI Eyewear and Article One</p>
                </Box>
            </Box>
            }
            {policy &&
              <Box className="settingsDialogContent">
                <Button onClick={() => viewPolicy(false)} variant='text' className='policy' ><BackArrow /> Back</Button>
                <h2>Privacy Policy</h2>
                <Box>
                    <h3>Effective date: August 08, 2022</h3>
                    <p>The Optical Foundry (“us”, “we”, or “our”) operates the The Optical Foundry mobile application (the “Service”).</p>
                    <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data. Our Privacy Policy for The Optical Foundry is created with the help of the Free Privacy Policy Generator.</p>
                    <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>
                    <h3>Information Collection And Use</h3>
                    <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
                    <p>We use TrueDepth API technologies (ARKit, with automatic estimation of the real-world directional lighting environment) to create AR effects within the App. We do not share information with third parties, do not store or process in any other way the data which we accessed and used via TrueDepth API. For more information about TrueDepth API technologies you may visit https://support.apple.com/en-us/HT208108.</p>
                    <p>Facial(Face-tracking) data is not collected, shared, or stored in any file.</p>
                    <h3>Types of Data Collected</h3>
                    <h4>Personal Data</h4>
                    <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:</p>
                    <ul>
                      <li>Cookies and Usage Data</li>
                    </ul>
                    <h4>Usage Data</h4>
                    <p>When you access the Service by or through a mobile device, we may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data (“Usage Data”).</p>
                    <h4>Tracking & Cookies Data</h4>
                    <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>
                    <p>Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</p>
                    <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
                    <p>Examples of Cookies we use:</p>
                    <ul>
                      <li>Session Cookies. We use Session Cookies to operate our Service.</li>
                      <li>Preference Cookies. We use Preference Cookies to remember your preferences and various settings.</li>
                      <li>Security Cookies. We use Security Cookies for security purposes.●	Security Cookies. We use Security Cookies for security purposes.</li>
                    </ul>
                    <h3>Use of Data</h3>
                    <p>The Optical Foundry uses the collected data for various purposes:</p>
                    <ul>
                      <li>To provide and maintain the Service</li>
                      <li>To notify you about changes to our Service</li>
                      <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
                      <li>To provide customer care and support</li>
                      <li>To provide analysis or valuable information so that we can improve the Service</li>
                      <li>To monitor the usage of the Service</li>
                      <li>To detect, prevent and address technical issues</li>
                    </ul>
                    <h3>Transfer Of Data</h3>
                    <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
                    <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
                    <p>The Optical Foundry will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
                    <h3>Device permissions for Personal Data access</h3>
                    <p>Depending on the User's specific device, this Application may request certain permissions that allow it to access the User's device Data as described below.</p>
                    <p>By default, these permissions must be granted by the User before the respective information can be accessed. Once the permission has been given, it can be revoked by the User at any time. In order to revoke these permissions, Users may refer to the device settings or contact the Owner for support at the contact details provided in the present document.</p>
                    <p>The exact procedure for controlling app permissions may be dependent on the User's device and software. Please note that the revoking of such permissions might impact the proper functioning of this Application.</p>
                    <p>If User grants any of the permissions listed below, the respective Personal Data may be processed (i.e accessed to, modified or removed) by this Application:</p>
                    <ul>
                      <li>Camera permission - Used for accessing the camera to enable AR functionalities of the app that uses camera to track face-tracking.</li>
                    </ul>
                    <h3>Disclosure Of Data</h3>
                    <h4>Legal Requirements</h4>
                    <p>The Optical Foundry may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
                    <ul>
                      <li>To comply with a legal obligation</li>
                      <li>To protect and defend the rights or property of The Optical Foundry</li>
                      <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                      <li>To protect the personal safety of users of the Service or the public</li>
                      <li>To protect against legal liability</li>
                    </ul>
                    <h3>Security Of Data</h3>
                    <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
                    <h3>Service Providers</h3>
                    <p>We may employ third party companies and individuals to facilitate our Service (“Service Providers”), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</p>
                    <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
                    <h3>Links To Other Sites</h3>
                    <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
                    <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
                    <h3>Children's Privacy</h3>
                    <p>Our Service does not address anyone under the age of 4.</p>
                    <p>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>
                    <h3>Changes To This Privacy Policy</h3>
                    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                    <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the “effective date” at the top of this Privacy Policy.</p>
                    <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                    <h3>Contact Us</h3>
                    <p>If you have any questions about this Privacy Policy, please contact us:</p>
                    <ul>
                      <li>By email: info@theopticalfoundry.com</li>
                    </ul>
                </Box>
            </Box>
            }
      </Dialog>
      {download && <DownloadPassword download={download} setDownload={setDownload} />}
    </>
  )
}