import React, { useState, useEffect } from 'react';
import logo from '../logo.svg';
import '../styles/MainView.scss';
import Menu from './Menu';
import Home from './Home';
import Collections from './Collections';
import Profile from './Profile';
import Search from './Search';

export default function MainView({isOffline, isIOS, updatesWaiting, defferedPrompt, setDefferedPrompt}) {

  const [view, setView] = useState('home')

  const [subView, setSubView] = useState({
      view: '',
      id: '',
      name: '',
      last: {
        view: '',
        id: '',
        name: '',
      }
  })  

  const View = ({ type }) => {
    switch (type) {
      case "home":
        return <Home setView={setView} defferedPrompt={defferedPrompt} setDefferedPrompt={setDefferedPrompt} isIOS={isIOS} />
      case "collections":
          return <Collections subView={subView} setSubView={setSubView} isOffline={isOffline} isIOS={isIOS} updatesWaiting={updatesWaiting} />
      case "profile":
          return <Profile setView={setView} subView={subView} setSubView={setSubView} isOffline={isOffline} updatesWaiting={updatesWaiting} />
      case "search":
        return <Search setView={setView} subView={subView} setSubView={setSubView} updatesWaiting={updatesWaiting} isOffline={isOffline} />
      default:
        return <Home setView={setView} setSubView={setSubView} defferedPrompt={defferedPrompt} setDefferedPrompt={setDefferedPrompt} isIOS={isIOS} />
    }
  }

  return (
    <div id='mainView' className={`page-wrapper-${view === 'collections' ? subView.view === "" ? 'brands' : subView.view : view}`}>
        <View type={view}/>
        <Menu view={view} setView={setView} setSubView={setSubView} />
    </div>
  )
}
