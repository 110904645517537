import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import CloseIcon from '../CloseIcon';

export default function NewSubscription({openNew, setOpenNew, setOpenAdd, db, setSaved, saved}) {

    const [name, setName] = React.useState('');

    const handleClose = () => {
        setOpenNew(false)
        setOpenAdd(true)
    }

    const collectionName = (e) => {
        setName(e.target.value)
    }

    const productAddNewClick = async (e) => {
        
        const id = name.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
            if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
            return index === 0 ? match.toLowerCase() : match.toUpperCase();
          }).replace(/[^a-zA-Z0-9 ]/g, "");;

        const newCollection = {
            'id': id,
            'name': name,
            'products': []
        }
        
        await db.add('saved', newCollection);
        setSaved([...saved, newCollection])
        handleClose()
      }

  return (
    <Dialog
        open={openNew}
        onClose={handleClose}
        className='settingsDialog'
        style={{
            backgroundColor: 'transparent',
            overflowY: 'scroll',
          }}
      >
            
              <CloseIcon handleClose={handleClose} label="Close new collection" />
            
            <Box className="settingsDialogContent" style={{paddingTop: '7px', paddingBottom: '50px'}}>
                <h2>New Collection</h2>
                <input 
                    placeholder='Name your collection' 
                    aria-label='Name your collection' 
                    name="name-your-collection"
                    id="name-your-collection"
                    onChange={collectionName} 
                    style={{
                        border: 'none',
                        width: '100%',
                        backgroundColor: '#F2F2F2',
                        borderRadius: '12px',
                        padding: '12px 17px',
                        fontSize: '16px',
                        color: '#757575',
                        marginTop: '41px',
                        marginBottom: '10px'
                    }}      
                />
                <Button onClick={productAddNewClick} variant='outlined' className='product' disabled={name != '' ? false : true}>Add Collection</Button>
            </Box>
      </Dialog>
  )
}
