import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { openDB } from 'idb';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import PageHeader from '../../components/PageHeader';
import '../../styles/Collections.scss';
import Loading from '../../components/Loading';
import logo from '../../images/of-logo.png';
import SeeAll from '../../images/see-all.jpg'
import { toDataURL } from '../../utils/offline-image';

export default function Brands({subView, setSubView, isOffline}) {

    const [collections, setCollections] = useState([])
    const [brandLogo, setBrandLogo] = useState({})
    const [isLoading, setIsLoading] = useState(true)
  
    const getCollections = async () => {
        let getCollections;
        const getDB = await openDB(process.env.REACT_APP_INDB_NAME)
        const getBrand = await getDB.getAllFromIndex('collections', 'id', Number(process.env.REACT_APP_TOP_LEVEL_ID))
        const getTitle = getBrand[0].description.match(/<h1.*?>(.*)<\/h1>/i)?.[1] || ''

        let buildBrandLogo = {
            title: getTitle,
            image_url: getBrand[0].image_url
        }

        if (isOffline) {
            let getCollectionsData = await getDB.getAllFromIndex('collections', 'parent_id', Number(process.env.REACT_APP_TOP_LEVEL_ID))
            getCollections = []
            for (let i=0; i < getCollectionsData.length; i++) {
                if (getCollectionsData[i].download) {
                    getCollections.push({
                        ...getCollectionsData[i],
                        image_data_url: await toDataURL(getCollectionsData[i].image_url)
                    })
                }
            }
            getCollections.sort((a,b) => a.sort_order - b.sort_order)
            buildBrandLogo.image_data_url = await toDataURL(buildBrandLogo.image_url)
        }

        else {
            getCollections = await getDB.getAllFromIndex('collections', 'parent_id', Number(process.env.REACT_APP_TOP_LEVEL_ID))
            getCollections.sort((a,b) => a.sort_order - b.sort_order)
        }
        
        setBrandLogo(buildBrandLogo)
        setCollections(getCollections)
        setIsLoading(false)
    }

    console.log('brandLogo', collections)
  
    useEffect(() => {
     getCollections()
    }, []);

  return (
    <Box className='background-color-wrapper' style={{ backgroundColor: subView.view === "" ? process.env.REACT_APP_PRIMARY_BG : process.env.REACT_APP_SECONDARY_BG}}>
        {isLoading ?
            <Loading light={true} />
        :
            <Box className='app-sub-page collection'>
                <Box className='collection-grid brands'>
                    <Grid container direction="row" className='collection-grid-container' style={{marginBottom: '50px'}}>
                        <Grid key={'000'} item className={`collection-grid-item collection-grid-item-header ${process.env.REACT_APP_APP_LOGO && process.env.REACT_APP_SHOW_OF_LOGO === 'true' ? 'brand-header' : ''}`}>
                            {process.env.REACT_APP_APP_LOGO &&
                                <div className='collection-grid-image-header'><img src={process.env.REACT_APP_APP_LOGO} alt={process.env.REACT_APP_APP_NAME} /></div>
                            }
                            {process.env.REACT_APP_SHOW_OF_LOGO === 'true' &&
                                <div className='collection-grid-image-header'><img src="../../images/of-logo.png" alt={'The Optical Foundry'} /></div>
                            }
                        </Grid>
                        {collections.length > 0 && collections[0].level === 0 &&
                            <Grid item key={'all'} className="collection-grid-item">
                                <Button className='collection-grid-btn' onClick={(e) => setSubView({view: 'products', id: collections[0].parent_id, name: `All Glasses`, allCollections: true, last: subView})}>
                                    <img className='collection-grid-image' src={process.env.REACT_APP_SEE_ALL_IMG ? process.env.REACT_APP_SEE_ALL_IMG : SeeAll} alt="See All" />
                                </Button>
                            </Grid>
                        }
                        {collections.length > 0 &&
                            collections.map((c) => {
                                let imgSrc = c.image_url
                                if (c.image_data_url) {
                                    imgSrc = c.image_data_url;
                                }
                                return (
                                    <Grid key={c.id} item className="collection-grid-item">
                                        <Button className='collection-grid-btn' onClick={(e) => setSubView({view: c.level === 0 ? 'products' : 'collections', id: c.id, name: c.name, last: subView})}>
                                            <img className='collection-grid-image' src={imgSrc} alt={c.name} />
                                        </Button>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Box>
            </Box>
        }
    </Box>
  )
}
