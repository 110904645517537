import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import CloseIcon from '../CloseIcon';

export default function NewSubscription({open, setOpen, db, setSaved, saved, selected, setSelected}) {

    const [name, setName] = React.useState('');

    useEffect(() => {
        setName(selected.name)
    }, [selected]);

    const handleClose = () => {
        setSelected({})
    }

    const collectionName = (e) => {
        setName(e.target.value)
    }

    const productAddNewClick = async (e) => {
        
        if (open === 'new') {
            const id = name.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
                if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
                return index === 0 ? match.toLowerCase() : match.toUpperCase();
              }).replace(/[^a-zA-Z0-9 ]/g, "");;
    
            const newCollection = {
                'id': id,
                'name': name,
                'products': []
            }
            
            await db.add('saved', newCollection);
            setSaved([...saved, newCollection])
            setSelected({})
        }
        else if (open === 'edit') {

            const newCollection = {...selected}

            newCollection.name = name
            
            await db.put('saved', newCollection);

            let updateList;
    
            updateList = [...saved]
            let updatedRemoved = updateList.filter(f => f.id !== selected.id)
            updatedRemoved.push(newCollection)
            setSaved([newCollection])
            setSelected({})
        }
        
      }

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        className='settingsDialog'
        style={{
            backgroundColor: 'transparent',
            overflowY: 'scroll',
          }}
      >
            
              <CloseIcon handleClose={handleClose} label="Close new/edit collections" />
            
            <Box className="settingsDialogContent" style={{paddingTop: '7px', paddingBottom: '50px'}}>
                <h2>{open === 'new' ? 'New Collection' : 'Edit Collection'}</h2>
                <input 
                    placeholder='Name your collection' 
                    aria-label='Name your collection' 
                    name="name-your-collection"
                    id="name-your-collection"
                    value={name}
                    onChange={collectionName} 
                    style={{
                        border: 'none',
                        width: '100%',
                        backgroundColor: '#F2F2F2',
                        borderRadius: '12px',
                        padding: '12px 17px',
                        fontSize: '16px',
                        color: '#757575',
                        marginTop: '41px',
                        marginBottom: '10px'
                    }}      
                />
                <Button onClick={productAddNewClick} variant='outlined' className='product' disabled={name != '' ? false : true}>{open === 'new' ? 'Add Collection' : 'Update Collection'}</Button>
            </Box>
      </Dialog>
  )
}
