import { openDB, deleteDB } from 'idb';

// Create IndexedDB database and stores
async function createDB() {
  //await deleteDB(process.env.REACT_APP_INDB_NAME)
  const db = await openDB(process.env.REACT_APP_INDB_NAME, 1, {
    upgrade(db, oldVersion, newVersion, transaction) {
      // Switch over the oldVersion, *without breaks*, to allow the database to be incrementally upgraded.
    switch(oldVersion) {
      case 0:
      case 1:
        // Create a store of objects
        const collections = db.createObjectStore('collections', {
            autoIncrement: true,
            keyPath: 'id'
        });
        collections.createIndex('id', 'id');
        collections.createIndex('parent_id', 'parent_id');

        const products = db.createObjectStore('products', {
            autoIncrement: true,
            keyPath: 'id'
        });
        products.createIndex('id', 'id');
        products.createIndex('brand_id', 'brand_id');
        products.createIndex('collection_id', 'collection_id');

        const favorites = db.createObjectStore('favorites', {
            autoIncrement: true,
            keyPath: 'id'
        });
        favorites.createIndex('id', 'id');

        const saved = db.createObjectStore('saved', {
            autoIncrement: true,
            keyPath: 'id'
        });
        saved.createIndex('id', 'id');
        saved.createIndex('name', 'name');

        const arRefApple = db.createObjectStore('ar-ref-apple', {
          autoIncrement: true,
          keyPath: 'id'
        });
        arRefApple.createIndex('id', 'id');

    }
   }
  });
  return(db)
}

const formatCategories = (categories) => {
  const formatted = []

  formatted.push({
    id: categories.entityId,
    parent_id: null,
    name: categories.name,
    image_url: categories.image ? categories.image.url : "",
    sort_order: null,
    description: categories.description,
    level: null
  })

  categories.children.forEach((c1, c1index) => {
    formatted.push({
      id: c1.entityId,
      parent_id: parseInt(process.env.REACT_APP_TOP_LEVEL_ID),
      name: c1.name,
      image_url: c1.image ? c1.image.url : "",
      sort_order: c1index,
      download: c1.description.toLowerCase().includes('download') ? true : false,
      level: c1.children.length
    })
    if (c1.children?.length > 0) {
      c1.children.forEach((c2, c2index) => {
        formatted.push({
          id: c2.entityId,
          parent_id: c1.entityId,
          name: c2.name,
          image_url: c2.image ? c2.image.url : "",
          sort_order: c2index,
          download: c2.description.toLowerCase().includes('download') ? true : false,
          level: c2.children?.length || 0
        })
        if (c2.children?.length > 0) {
          c2.children.forEach((c3, c3index) => {
            formatted.push({
              id: c3.entityId,
              parent_id: c2.entityId,
              name: c3.name,
              image_url: c3.image ? c3.image.url : "",
              sort_order: c3index,
              download: c3.description.toLowerCase().includes('download') ? true : false,
              level: c3.children?.length || 0
            })
          })
        }
      })
    }
  })

  return formatted
}

const formatProducts = (products, sort_order) => {
  let formatted = []
  products.forEach((p, i) => {
    const categories = p.node.categories.edges.find(c => (c.node.breadcrumbs.edges[0].node.entityId === parseInt(process.env.REACT_APP_TOP_LEVEL_ID) && c.node.breadcrumbs.edges.length > parseInt(process.env.REACT_APP_BREADCRUMB_DEPTH)) || (c.node.breadcrumbs.edges[0].node.entityId === parseInt(process.env.REACT_APP_TOP_LEVEL_ID) && c.node.name.toLowerCase().includes('earlier releases')) || (c.node.breadcrumbs.edges[0].node.entityId === parseInt(process.env.REACT_APP_TOP_LEVEL_ID) && c.node.name.toLowerCase().includes('designer picks')))
    const limitColors = p.node.customFields.edges.find(c => c.node.name === `colors-${process.env.REACT_APP_ID}`)
    formatted.push({
      name: p.node.name,
      description: p.node.description,
      image_url: p.node.defaultImage.url,
      id: p.node.entityId,
      brand_id: categories ? categories.node.breadcrumbs.edges[categories.node.breadcrumbs.edges.length - 2].node.entityId : "",
      collection_id: categories ? categories.node.breadcrumbs.edges[categories.node.breadcrumbs.edges.length - 1].node.entityId : "",
      custom_fields: p.node.customFields,
      variants: {
       edges: limitColors ? p.node.variants.edges.filter(v => limitColors.node.value.includes(v.node.productOptions.edges[0].node.values.edges[0].node.label)) : p.node.variants.edges
      },
      sort_order: sort_order.find(s => s.id === p.node.entityId).sort_order
    })
  })
  return formatted
}

const waitGetAllData = []
const waitGetInitData = []
let products = [];
let productsInit = []

// Store API data to indexedDB
export async function getAppData() {
  return new Promise(async (resolve, reject) => {
    try {
      const db = await createDB()

      const getAppData = await fetch(`${process.env.REACT_APP_API_URL}/get-app-data?id=${process.env.REACT_APP_TOP_LEVEL_ID}&levels=${process.env.REACT_APP_LEVEL_DEPTH}`)
      const appData = await getAppData.json()

      console.log("appData", appData)

      const tx = db.transaction('collections', 'readwrite');
      const objectStore = tx.objectStore("collections");
      const objectStoreRequest = objectStore.clear();

      const categories = formatCategories(appData.categories)

      for (let i=0; i < categories.length; i++) {
        await tx.store.put(categories[i])
      }

      const products = formatProducts(appData.products, appData.sort_order)

      const tx2 = db.transaction('products', 'readwrite');
      const objectStore2 = tx2.objectStore("products");
      const objectStoreRequest2 = objectStore2.clear();

      for (let i=0; i < products.length; i++) {
        await tx2.store.put(products[i])
      }
      
      await tx.done
      await tx2.done

      resolve()
    }
    catch (err) {
      console.log(err.message)
      reject(err)
    }
  })
}

const toDataURL = url => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }))

export async function updateAppData(downloadCount, setDownloadCount) {
  return new Promise(async (resolve, reject) => {
    
    try {
      const cacheAvailable = 'caches' in window.self;
      if (cacheAvailable) {
        
        //const deleteCache = await caches.delete(process.env.REACT_APP_INDB_NAME);
        const cache = await caches.open(process.env.REACT_APP_INDB_NAME);
        const db = await openDB(process.env.REACT_APP_INDB_NAME)

        const getKeys = await cache.keys()
        for (let i=0; i < getKeys.length; i++) {
          await cache.delete(getKeys[i])
        }

        const getCategoryData = await db.getAllFromIndex('collections', 'id')
        const getProductsData = await db.getAllFromIndex('products', 'id')

        const categoriesToDownload = getCategoryData.filter(c => c.download)
        let startCount = 0

        if (getCategoryData.length > 0) {
          for (let i=0; i < getCategoryData.length; i++) {
            await cache.delete(getCategoryData[i].image_url);
            if (getCategoryData[i].download) {
              await cache.add(getCategoryData[i].image_url);
              startCount++
              setDownloadCount({
                title: 'Collections',
                total: categoriesToDownload.length,
                cur: startCount
              })
            }
          }
          setDownloadCount(false)
          startCount = 0
        }

        if (getProductsData.length > 0) {
          let tempCount = 0
          let tempTotal = 0

          const getProductsToDownload = getProductsData.filter(p => categoriesToDownload.findIndex(c => c.id === p.collection_id) > -1)

          setDownloadCount({
            title: 'Glasses',
            total: getProductsToDownload.length,
            cur: startCount
          })

          const getArUrls = await fetch(`${process.env.REACT_APP_API_URL}/get-sayduck-urls`)
          const arUrls = await getArUrls.json()

          await db.clear('ar-ref-apple');

          for (let i=0; i < getProductsData.length; i++) {
            await cache.delete(getProductsData[i].image_url);
            let shouldDownload = false
            let uuid, arFiles
            if (getProductsToDownload.findIndex(p => p.id === getProductsData[i].id) > -1) {
              await cache.add(getProductsData[i].image_url);
              shouldDownload = true
              uuid = getProductsData[i].custom_fields.edges.find(f => f.node.name === 'app-sayduck').node.value
              arFiles = arUrls.find(u => u.uuid === uuid)
              tempCount++
              console.log("tempCount", tempCount)
            }
            if (getProductsData[i].variants.edges.length > 0) { 
              for (let j=0; j < getProductsData[i].variants.edges.length; j++) {
                await cache.delete(`${getProductsData[i].variants.edges[j].node.defaultImage.url}`)
                if (uuid) {
                  const arUrl = arFiles && arFiles.liveConfigurations.nodes[0].liveVariants.nodes.find(f => f.name.split(' ')[0] === getProductsData[i].variants.edges[j].node.productOptions.edges[0].node.values.edges[0].node.label.split(' ')[0]) || false
                  if (arUrl) {
                    await cache.delete(`${arUrl.arAppleAssetUrl}`)
                    if (shouldDownload) {
                      await cache.add(`${arUrl.arAppleAssetUrl}`);
                      await db.put('ar-ref-apple', {
                        url: `${arUrl.arAppleAssetUrl}`,
                        id: getProductsData[i].variants.edges[j].node.entityId
                      });
                    }
                  }
                  if (shouldDownload) {
                    await cache.add(getProductsData[i].variants.edges[j].node.defaultImage.url);
                  }
                }
              }
            }
            if (shouldDownload) {
              startCount++
              setDownloadCount({
                title: 'Glasses',
                total: getProductsToDownload.length,
                cur: startCount
              })
            }
          }
          setDownloadCount(false)
        }
      }
      
      resolve()
    }
    catch (err) {
      console.log(err.message)
      alert(err.message)
      reject(err)
    }
  })
}