import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SettingsIcon from './SettingsIcon';
import Settings from './Settings';
import {BackArrow} from '../utils/icons';
import '../styles/Page.scss';

export default function SubPageHeader({title, subView, setSubView, viewer, setViewer, updatesWaiting}) {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleBackBtn = () => {
        if (viewer) {
            setViewer(false)
        }
        else {
            setSubView(subView.last)
        }
  }

  console.log(subView)

  return (
    <Box className='pageHeader subPageHeader'>
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <Grid item>
                <Button className='settings-icon-btn' onClick={(e) => handleBackBtn()} aria-label="Back to previous view">
                    <BackArrow />
                </Button>
            </Grid>
            <Grid item>
                <SettingsIcon handleClickOpen={handleClickOpen} updatesWaiting={updatesWaiting} />
            </Grid>
        </Grid>
        <h1>{title}</h1>
        <Settings open={open} handleClose={handleClose} updatesWaiting={updatesWaiting} />
    </Box>
  )
}
