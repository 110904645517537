import React, { useState, useEffect } from 'react';
import { openDB } from 'idb';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import PageHeader from '../components/PageHeader';
import { Remove } from '../utils/icons';
import '../styles/Profile.scss';
import SavedCollections from '../components/profile/SavedCollections'
import { toDataURL } from '../utils/offline-image';

function TabPanel1(props) {
    const { children, value, index, items, store, removeClick, setOpen, open, db, setSaved, saved, setView, subView, setSubView, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            >
            {value === 0 && (
                <Box>
                {items.length > 0 && 
                    items.map(item => {
                        const i = item[0]
                        return (
                            <Grid key={i.id} container direction="row" alignItems={'center'} justifyContent="space-between" className='profile-grid-container'>
                                <Grid item>
                                    <Grid key={i.id} container direction="row" alignItems={'center'}>
                                        <Grid item>
                                        <Button className='collection-grid-btn profile' onClick={(e) => {
                                        setSubView({view: 'product', id: i.id, name: i.name, last: subView})
                                        setView('collections')
                                    }}><img className='profile-image' src={i.image_data_url && i.image_data_url ? i.image_data_url : i.image_url} alt="" width={'100%'} /></Button>
                                    </Grid>
                                        <Grid item>
                                            <p>{i.name}</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item textAlign={'right'}>
                                    <Button className='collection-grid-btn' onClick={(e) => removeClick(e, i.id, store)} aria-label={`Remove item ${i.name} from ${store}`}><Remove /></Button>
                                </Grid>
                            </Grid>
                        )
                    })
                }
                </Box>
            )}
            {value === 1 && (
                <SavedCollections props={props} />
            )}
        </div>
    );
}

function TabPanel2(props) {
    const { children, value, index, items, store, removeClick, setOpen, open, db, setSaved, saved, setView, subView, setSubView, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            >
            {value === 1 && (
                <SavedCollections props={props} />
            )}
        </div>
    );
}

TabPanel1.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
TabPanel2.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Profile({setView, subView, setSubView, isOffline, updatesWaiting}) {

  const [db, setDB] = useState(null)
  const [saved, setSaved] = useState([])
  const [favorites, setFavorites] = useState([])
  const [open, setOpen] = React.useState('');

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getProfile = async () => {
    const getDB = await openDB(process.env.REACT_APP_INDB_NAME)
    setDB(getDB)

    let getSaved = await getDB.getAllFromIndex('saved', 'id')

    if (isOffline) {
        for (let i=0; i < getSaved.length; i++) {
            for (let j=0; j < getSaved[i].products.length; j++) {
                getSaved[i].products[j].image_data_url = await toDataURL(getSaved[i].products[j].image)
            }
        }
    }

    setSaved(getSaved)

    const getFavorites = await getDB.getAllFromIndex('favorites', 'id')
    const getFavoritesProducts = []
    for (let i=0; i < getFavorites.length; i++) {
        let getProduct = await getDB.getAllFromIndex('products', 'id', Number(getFavorites[i].id))
        if (isOffline) {
            getProduct[0].image_data_url = await toDataURL(getProduct[0].image_url)
        }
        getFavoritesProducts.push(getProduct)
    }
    setFavorites(getFavoritesProducts)
  }

  const removeClick = async (e, id, store, lId = '') => {
    e.preventDefault()

    let updateList;

    if (store === 'favorites') {
        await db.delete('favorites', Number(id));
        updateList = [...favorites]
        let updatedRemoved = updateList.filter(f => f.id !== id)
        setFavorites(updatedRemoved)
    }
    else if (store === 'saved') {
        updateList = [...saved]
        const tempItemIndex = updateList.findIndex(f => f.id === lId)
        if (tempItemIndex > -1) {
            let removeProdIndex = updateList[tempItemIndex].products.findIndex(f => f.id === id)
            if (removeProdIndex > -1) {
                updateList[tempItemIndex].products.splice(removeProdIndex, 1)
                await db.put('saved', updateList[tempItemIndex]);
                setSaved(updateList)
            }
        }

    }
  }

  useEffect(() => {
   getProfile()
  }, []);

  return (
    <Box className='app-page profile'>
        <Box className='app-sub-page'>
            <PageHeader title={'Profile'} updatesWaiting={updatesWaiting} />
            <Box>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab className='profile-tab-btn' label="Favorites" {...a11yProps(0)} />
                    <Tab className='profile-tab-btn' label="Collections" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel1 value={value} index={0} items={favorites} store="favorites" removeClick={removeClick} setOpen={setOpen} open={open} setSaved={setSaved} saved={saved} setView={setView} subView={subView} setSubView={setSubView} />
            <TabPanel2 value={value} index={1} items={saved} store="saved" removeClick={removeClick} setOpen={setOpen} open={open} db={db} setSaved={setSaved} saved={saved} setView={setView} subView={subView} setSubView={setSubView} />
        </Box>
    </Box>
  )
}
