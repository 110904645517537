import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SettingsIcon from './SettingsIcon'
import Settings from './Settings'
import '../styles/Page.scss';

export default function PageHeader({title, updatesWaiting}) {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


  return (
    <Box className='pageHeader'>
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <Grid item>
                <h1>{title}</h1>
            </Grid>
            <Grid item>
                <SettingsIcon handleClickOpen={handleClickOpen} updatesWaiting={updatesWaiting} />
            </Grid>
        </Grid>
        <Settings open={open} handleClose={handleClose} updatesWaiting={updatesWaiting} />
    </Box>
  )
}
