import React, { useState, useEffect } from 'react';
import { openDB } from 'idb';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import '../styles/Home.scss';
import InstallButton from '../components/InstallButton';

import { useSelector } from 'react-redux';

import { SW_INIT, SW_UPDATE } from '../types';

export default function Home({setView, defferedPrompt, setDefferedPrompt, isIOS}) {

  const state = useSelector(state => state);

  const [data, updateData] = useState();

  useEffect(() => {
    const getData = async () => {
      const getDB = await openDB(process.env.REACT_APP_INDB_NAME)
      const getCategory = await getDB.getAllFromIndex('collections', 'id', Number(process.env.REACT_APP_TOP_LEVEL_ID))
      const getTitle = getCategory[0].description.match(/<h1.*?>(.*)<\/h1>/i)
      const getTagLine = getCategory[0].description.match(/<h2.*?>(.*)<\/h2>/i)
      updateData({
        title: getTitle ? getTitle[1] : 'The Optical Foundry',
        tagLine: getTagLine ? getTagLine[1] : '',
        description: getCategory[0].description.replace(/(<h1.*>.*<\/h1>)/i, '').replace(/(<h2.*>.*<\/h2>)/i, '').replaceAll('\r', '').replaceAll('\n', ''),
      });
    }
    getData();
  }, []);

  return (
    data && <Box sx={{ 
        backgroundColor: process.env.REACT_APP_PRIMARY_BG 
      }} id="home">
      {process.env.REACT_APP_HOME_IMG && <Box className='backgroundOverlay'><img src={process.env.REACT_APP_HOME_IMG} /></Box>}  
      <InstallButton defferedPrompt={defferedPrompt} setDefferedPrompt={setDefferedPrompt} isIOS={isIOS} />
      <Button
        onClick={(e) => setView('collections')}
        className='homeBtn'
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          minHeight={'100%'}
          flexWrap={'nowrap'}
          {...!process.env.REACT_APP_HOME_IMG && {
            sx: {
              justifyContent: 'center',
            }
          }}
        >
          <Grid item className={`home-image-header ${process.env.REACT_APP_APP_LOGO && process.env.REACT_APP_SHOW_OF_LOGO === 'true' ? 'with-brand-image' : 'no-brand-image'}`}>
            {process.env.REACT_APP_APP_LOGO && 
              <div><img src={process.env.REACT_APP_APP_LOGO} alt={process.env.REACT_APP_APP_NAME} /></div>
            }
            {process.env.REACT_APP_SHOW_OF_LOGO === 'true' &&
              <div><img src="../images/of-logo.png" alt="The Optical Foundry" /></div>
            }
          </Grid>
          {data.tagLine &&
            <Grid item>
              <Box style={{marginTop: '30px', color: process.env.REACT_APP_HOME_TEXT}} dangerouslySetInnerHTML={{ __html: data.tagLine }}></Box>
            </Grid>
          }
          <Grid item>
            <Box sx={{ backgroundColor: process.env.REACT_APP_BUTTON_BG, color: process.env.REACT_APP_BUTTON_TEXT }} className='homeFakeBtn'
            >VIEW COLLECTIONS</Box>
          </Grid>
          <Grid item>
            <Box style={{width: '95%', maxWidth: "500px", textTransform: "none", marginTop: "50px", fontWeight: "normal", textAlign: "justify", marginLeft: "auto", marginRight: "auto", color: process.env.REACT_APP_HOME_TEXT}} dangerouslySetInnerHTML={{ __html: data.description }}></Box>
          </Grid>
        </Grid>
      </Button>
    </Box>
  )
}
