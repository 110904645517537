import React, { useState, useEffect, useRef } from 'react';
import { openDB } from 'idb';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SubPageHeader from '../../components/SubPageHeader';
import { Save, Saved, Add } from '../../utils/icons';
import Loading from '../../components/Loading';
import '../../styles/Collections.scss';
import AddSubscription from '../../components/products/AddSubscription';
import { toDataURL } from '../../utils/offline-image';

export default function Products({subView, setSubView, isOffline, isIOS, updatesWaiting}) {

  const [products, setProducts] = useState([])
  const [viewer, setViewer] = useState(false)
  const [db, setDB] = useState(null)
  const [saved, setSaved] = useState([])
  const [favorites, setFavorites] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [openAdd, setOpenAdd] = useState(false)
  const [selected, setSelected] = React.useState({});
  const [selectedOption, setSelectedOption] = useState({})
  const [api, setAPI] = useState(null)
  const [sayduckInit, setSayduckInit] = useState(false)
  const [topPosition, setTopPosition] = useState(null)

  const ref = useRef(null);

  const getProducts = async () => {

    //console.log('getProducts', subView)
    
    const getDB = await openDB(process.env.REACT_APP_INDB_NAME)
    setDB(getDB)

    const getSaved = await getDB.getAllFromIndex('saved', 'id')
    setSaved(getSaved)

    const getFavorites = await getDB.getAllFromIndex('favorites', 'id')
    setFavorites(getFavorites)

    let getProducts

    if (isOffline) {
        const getProductsDataAll = await getDB.getAllFromIndex('products', (subView.allCollections ? 'brand_id' : 'collection_id'), Number(subView.id))
        let getProductsData = [...getProductsDataAll]
        getProducts = []

        if (subView.allCollections) {
            let getCollectionsData = await getDB.getAllFromIndex('collections', 'parent_id', Number(subView.id))
            const categoriesToDownload = getCollectionsData.filter(c => c.download)
            getProductsData = getProductsDataAll.filter(p => categoriesToDownload.findIndex(c => c.id === p.collection_id) > -1)
        }

        for (let i=0; i < getProductsData.length; i++) {
            for (let j=0; j < getProductsData[i].variants.edges.length; j++) {
                const getArFileVar = await getDB.getAllFromIndex('ar-ref-apple', 'id', getProductsData[i].variants.edges[j].node.entityId)
                getProductsData[i].variants.edges[j].node['image_data_url'] = await toDataURL(getProductsData[i].variants.edges[j].node.defaultImage.url)
                getProductsData[i].variants.edges[j].node['ar_apple_url'] = getArFileVar.length > 0 ? getArFileVar[0].url : false
            }
            const getArFile = await getDB.getAllFromIndex('ar-ref-apple', 'id', getProductsData[i].variants.edges[0].node.entityId)
            getProducts.push({
                ...getProductsData[i],
                image_data_url: await toDataURL(getProductsData[i].image_url),
                ar_apple_url: getArFile.length > 0 ? getArFile[0].url : false
            })

        }
    }
    else {
        getProducts = await getDB.getAllFromIndex('products', (subView.allCollections ? 'brand_id' : 'collection_id'), Number(subView.id))
    }

    getProducts.sort(function(a, b) { return naturalCompare(a.name, b.name) })

    setProducts(getProducts)
    setIsLoading(false)
  }

  function naturalCompare(a, b) {
    var ax = [], bx = [];

    a.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
    b.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
    
    while(ax.length && bx.length) {
        var an = ax.shift();
        var bn = bx.shift();
        var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
        if(nn) return nn;
    }

    return ax.length - bx.length;
  }   

  const getViewer = (id) => {

    const product = products.find(p => p.id === id)

    const viewerContainer = document.querySelector('.sayduck-3d-viewer-container2')
    const sayduckViewer = document.createElement('sayduck-viewer')

    sayduckViewer.setAttribute('mode', "variants")
    sayduckViewer.setAttribute('background', "transparent")
    sayduckViewer.setAttribute('hide-controls-hint', "")
    sayduckViewer.setAttribute('hide-product-info', "")
    sayduckViewer.setAttribute('hide-embed', "")
    sayduckViewer.setAttribute('hide-dimensions', "")
    sayduckViewer.setAttribute('hide-annotations', "")
    sayduckViewer.setAttribute('hide-fullscreen', "")
    sayduckViewer.setAttribute('hide-branding', "")

    if (sayduckInit) {
        api.setActiveByUuid(product.custom_fields.edges.find(cf => cf.node.name === "app-sayduck").node.value);
    }

    if (selectedOption[product.id] !== undefined) {
        const vUUID = selectedOption[product.id].node.sku
        sayduckViewer.setAttribute('initial-variant', vUUID)
    }
    else {
        sayduckViewer.setAttribute('initial-variant', product.variants.edges[0].node.sku)
    }

    sayduckViewer.setAttribute('include-variants', `[${product.variants.edges.map(v => `"${v.node.sku}"`).join(',')}]`)

    viewerContainer.appendChild(sayduckViewer)
    sayduckViewer.setAttribute('product', product.custom_fields.edges.find(cf => cf.node.name === "app-sayduck").node.value)
    viewerContainer.style.display = 'block'

    const s = document.createElement('script')
    s.src = 'https://viewer.sayduck.com'
    document.body.appendChild(s)
  }

  useEffect(() => {
   getProducts();

   window.addEventListener('sayduck.api-ready', (event) => {
        setAPI(event.detail.instance)
        setSayduckInit(true)
    });
  }, []);

  useEffect(() => {
    if (!viewer) {
        if (document.querySelector('.sayduck-3d-viewer-container2')) {
            document.querySelector('.sayduck-3d-viewer-container2').style.display = 'none'
            document.querySelector('script[src="https://viewer.sayduck.com"]')?.remove()
            document.querySelector('sayduck-viewer')?.remove()
            setSayduckInit(false)
        }
    }
   }, [viewer]);

  const productCardClick = (e, p) => {
      e.preventDefault()
      console.log(e)
      setSubView({view: 'product', id: p.id, name: p.name, last: subView})
  }

  const productAddClick = async (e, id) => {
    e.preventDefault()
    e.stopPropagation();

    console.log('saved', saved)
    console.log('id', id)
    
    setSelected(products.find(f => f.id === id))
    setOpenAdd(true)
  }

  const productSaveClick = async (e, id, name, isFav) => {
    e.preventDefault()
    e.stopPropagation();
    console.log('save')

    if (isFav) {
        await db.delete('favorites', Number(id));
        const updatedFav = favorites.filter(f => f.id !== id)
        setFavorites(updatedFav)
    }
    else {
        await db.add('favorites', {
            'id': id,
            'name': name
        });
        setFavorites([...favorites, {id: id}])
    }
  }

  const productTryOnClick = (e, arURL, pId) => {
    e.preventDefault()
    e.stopPropagation();

    setTopPosition(pId)

    let getUrl = arURL

    if (selectedOption[pId] !== undefined) {
        getUrl = selectedOption[pId].node.ar_apple_url
    }

    caches.match(getUrl)
    .then(response => response.blob())
    .then((blob) => {
        const reader = new FileReader()
        reader.onloadend = () => {
            const toMime = reader.result.replace('data:application/zip', 'data:model/vnd.usdz+zip')
            const anchor = document.createElement('a');
            anchor.setAttribute('rel', 'ar');
            anchor.appendChild(document.createElement('img'));
            anchor.setAttribute('href', toMime);
            anchor.click();
        }
        reader.readAsDataURL(blob)
    })
  }

  const product360Click = (e, id, name) => {
    e.preventDefault()
    e.stopPropagation();

    setTopPosition(id)

    setViewer(name)
    getViewer(id)
  }

  const productSayduckTryon = () => {
    api.scene.launchWebAr();
  }

  useEffect(() => {
    if (ref.current && topPosition) {
        const scrollTo = ref.current.querySelector(`[data-card-key="${topPosition}"]`)
        scrollTo.scrollIntoView({behavior: 'instant', block: 'start'});
        setTopPosition(null)
      return;
    }
    else return
});

  return (
    <Box className='background-color-wrapper' style={{ backgroundColor: viewer ? "#FFFFFF" : process.env.REACT_APP_FRAME_BG}}>
        {isLoading ?
            <Loading />
        :
        <Box className='app-sub-page products'>
            <SubPageHeader title={viewer ? viewer : subView.name} subView={subView} setSubView={setSubView} viewer={viewer} setViewer={setViewer} updatesWaiting={updatesWaiting} />
            {!viewer &&
                <Box className='collection-grid'>
                    {products.length > 0 ? 
                        <Grid ref={ref} container direction="column" className='collection-grid-container'>
                            {products.map(p => {
                                    const isFav = favorites && favorites.length > 0 && favorites.find(f => p.id === f.id) || false
                                    const isSaved = saved && saved.length > 0 && saved.find(s => p.id === s.id) || false
                                    const isArURL = p.ar_apple_url && p.ar_apple_url !== "" ? p.ar_apple_url : false
                                    return (
                                        <Grid key={p.id} data-card-key={p.id} item className="collection-grid-item">
                                            <Box tabindex="0" role="button" aria-pressed="false" className='collection-grid-btn product-card' onClick={(e) => productCardClick(e, p)}>
                                                <Grid container direction="row">
                                                    <Grid item flexBasis={'100%'}>
                                                        <img className='product-image' src={selectedOption[p.id] !== undefined ? (selectedOption[p.id].node.image_data_url ? selectedOption[p.id].node.image_data_url : selectedOption[p.id].node.defaultImage.url) : p.variants.edges[0].node.image_data_url ? p.variants.edges[0].node.image_data_url : p.variants.edges[0].node.defaultImage.url} alt="" />
                                                    </Grid>
                                                    <Grid item flexBasis={'60%'}>
                                                        <h3>{p.name}</h3>
                                                        <p className='product-swatch-label'><strong>Color: </strong>{selectedOption[p.id] !== undefined ? selectedOption[p.id].node.productOptions.edges[0].node.values.edges[0].node.label : p.variants.edges[0].node.productOptions.edges[0].node.values.edges[0].node.label}</p>
                                                        <Grid container direction="row" className='product-swatch-options'>
                                                            {p.variants.edges && p.variants.edges.length > 0 &&
                                                                p.variants.edges.map((o, i) => {
                                                                    return (
                                                                        <Grid key={o.node.entityId} item className={`product-swatch-option ${selectedOption[p.id] !== undefined && o.node.entityId === selectedOption[p.id].node.entityId ? 'selected' : selectedOption[p.id] === undefined && i === 0 ? 'selected' : ''}`}>
                                                                            <Button className='collection-grid-btn product-swatch-btn' sx={{backgroundColor: `${o.node.productOptions.edges[0].node.values.edges[0].node.hexColors[0]}`}} onClick={(e) =>{
                                                                                e.preventDefault();
                                                                                e.stopPropagation();
                                                                                setSelectedOption({...selectedOption, [p.id]: o})
                                                                            }}>
                                                                            </Button>
                                                                        </Grid>
                                                                    )
                                                                })
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item flexBasis={'40%'} textAlign={'right'}>
                                                        <Button className='collection-grid-btn add-save' onClick={(e) => productSaveClick(e, p.id, p.name, isFav)} aria-label={isFav ? "Remove from favorites" : "Save to favorites"}>{isFav ? <Saved /> : <Save />}</Button>
                                                        <Button className={`collection-grid-btn add-save ${isSaved ? 'is-saved' : ''}`} onClick={(e) => productAddClick(e, p.id)} aria-label="Add to collections"><Add /></Button>
                                                    </Grid>
                                                    {isIOS && isArURL && isOffline &&
                                                        <Grid item flexBasis={`40%`} flexGrow={'1'} marginRight={'8px'} marginTop={'16px'}>
                                                            <Button onClick={(e) => productTryOnClick(e, isArURL, p.id)} variant='outlined' className='product'>TRY ON</Button>
                                                        </Grid>
                                                    }
                                                    {!isOffline &&
                                                        <Grid item flexBasis={'40%'} flexGrow={'1'} marginRight={'8px'} marginTop={'16px'}>
                                                            <Button variant='outlined' className='product' onClick={(e) => product360Click(e, p.id, p.name)}>TRY ON</Button>
                                                        </Grid>
                                                    }
                                                    <Grid item flexBasis={'40%'} flexGrow={'1'} marginLeft={'8px'} marginTop={'16px'}>
                                                        <Button variant='outlined' className='product' onClick={(e) => productCardClick(e, p)}>More Info</Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                        :
                        <p style={{textAlign: 'left'}}>No collections are assigned to this brand.</p>
                    }
                </Box>
            }
            <Box>
                <div className="sayduck-3d-viewer-container2" style={{minHeight: '400px', minWidth: '300px', width: '100%', height: '466px', position: 'relative', margin: '0 auto', paddingBottom: '66px', backgroundColor: '#FFFFFF', borderRadius: '12px'}}>{isIOS && <Button variant='outlined' className='product sayduck-tryon' onClick={(e) => productSayduckTryon()}>Try on</Button>}</div>
            </Box>
            <AddSubscription openAdd={openAdd} setOpenAdd={setOpenAdd} db={db} setSaved={setSaved} saved={saved} selected={selected} setSelected={setSelected} />
        </Box>
    }
    </Box>
  )
}
