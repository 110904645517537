import React, { useState, useEffect } from 'react';
import { openDB } from 'idb';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SubPageHeader from '../../components/SubPageHeader';
import SeeAll from '../../images/see-all.jpg'
import Loading from '../../components/Loading';
import { toDataURL } from '../../utils/offline-image';
import '../../styles/Collections.scss';

export default function Collection({subView, setSubView, isOffline, updatesWaiting}) {

  const [collections, setCollections] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const getCollections = async () => {
    let getCollections;
    const getDB = await openDB(process.env.REACT_APP_INDB_NAME)
    if (isOffline) {
        let getCollectionsData = await getDB.getAllFromIndex('collections', 'parent_id', Number(subView.id))
        getCollections = []
        for (let i=0; i < getCollectionsData.length; i++) {
            if (getCollectionsData[i].download) {
                getCollections.push({
                    ...getCollectionsData[i],
                    image_data_url: await toDataURL(getCollectionsData[i].image_url)
                })
            }
        }
        getCollections.sort((a,b) => a.sort_order - b.sort_order)
    }

    else {
        getCollections = await getDB.getAllFromIndex('collections', 'parent_id', Number(subView.id))
        getCollections.sort((a,b) => a.sort_order - b.sort_order)
    }
        
    setCollections(getCollections)
    setIsLoading(false)
  }

  useEffect(() => {
   getCollections()
  }, []);

  return (
    <Box className='background-color-wrapper' style={{ backgroundColor: subView.view === "" ? process.env.REACT_APP_PRIMARY_BG : process.env.REACT_APP_SECONDARY_BG}}>
        {isLoading ?
            <Loading />
        :
        <Box className='app-sub-page collection'>
            <SubPageHeader title={subView.name} subView={subView} setSubView={setSubView} updatesWaiting={updatesWaiting} />
            <Box className='collection-grid'>
                {collections.length > 0 ?
                    <Grid container  direction="row" className='collection-grid-container'>
                            <Grid item className="collection-grid-item">
                                <Button className='collection-grid-btn' onClick={(e) => setSubView({view: 'products', id: subView.id, name: `All ${subView.name}`, allCollections: true, last: subView})}>
                                    <img className='collection-grid-image' src={process.env.REACT_APP_SEE_ALL_IMG ? process.env.REACT_APP_SEE_ALL_IMG : SeeAll} alt="See All" />
                                </Button>
                            </Grid>
                            {
                                collections.map(c => {
                                    let imgSrc = c.image_url
                                    if (c.image_data_url) {
                                        imgSrc = c.image_data_url;
                                    }
                                    return (
                                        <Grid key={c.id} item className="collection-grid-item">
                                            <Button className='collection-grid-btn' onClick={(e) => setSubView({view: 'products', id: c.id, name: c.name, last: subView})}>
                                                <img className='collection-grid-image' src={imgSrc} alt={c.name} />
                                            </Button>
                                        </Grid>
                                    )
                                })
                            }
                    </Grid>
                :
                    <p style={{textAlign: 'left'}}>No collections are assigned to this brand.</p>
                }
            </Box>
        </Box>
        }
    </Box>
  )
}
