import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { MenuHomeActive, MenuHomeInactive, MenuCollectionActive, MenuCollectionInactive, MenuSearchActive, MenuSearchInactive, MenuProfileActive, MenuProfileInactive } from '../utils/icons';

import '../styles/Menu.scss';

export default function Menu({view, setView, setSubView}) {

  return (
    <nav id="menu">
      <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: process.env.REACT_APP_NAV_BG }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          flexWrap={'nowrap'}
        >
          <Grid item flexBasis={'25%'} maxWidth={160}>
            <Button
              className='menu-button'
              onClick={(e) => {setView('home')}}
            >
              <Box className='menu-button-icon'>{view === 'home' ? <MenuHomeActive /> : <MenuHomeInactive />}</Box>
              <Box className='menu-button-text'>Home</Box>
            </Button>
          </Grid>
          <Grid item flexBasis={'25%'} maxWidth={160}>
            <Button
              className='menu-button'
              onClick={(e) => {
                setSubView({
                  view: '',
                  id: '',
                  name: '',
                  last: {
                    view: '',
                    id: '',
                    name: '',
                  }
                })
                setView('collections')}
              }
            >
              <Box className='menu-button-icon'>{view === 'collections' ? <MenuCollectionActive /> : <MenuCollectionInactive />}</Box>
              <Box className='menu-button-text'>Collections</Box>
            </Button>
          </Grid>
          <Grid item flexBasis={'25%'} maxWidth={160}>
            <Button
              className='menu-button'
              onClick={(e) => {setView('search')}}
            >
              <Box className='menu-button-icon'>{view === 'search' ? <MenuSearchActive /> : <MenuSearchInactive />}</Box>
              <Box className='menu-button-text'>Search</Box>
            </Button>
          </Grid>
          <Grid item flexBasis={'25%'} maxWidth={160}>
            <Button
              className='menu-button'
              onClick={(e) => {setView('profile')}}
            >
              <Box className='menu-button-icon'>{view === 'profile' ? <MenuProfileActive /> : <MenuProfileInactive />}</Box>
              <Box className='menu-button-text'>Profile</Box>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </nav>
  )
}
