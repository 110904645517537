import React, { useState, useEffect } from 'react';
import { openDB } from 'idb';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import logo from '../images/ogi-logo.png';
import PageHeader from '../components/PageHeader';
import AllCollections from './collections/AllCollections';
import Collection from './collections/Collection';
import Products from './collections/Products';
import Product from './collections/Product';
import Brands from './collections/Brands';
import Loading from '../components/Loading';
import '../styles/Collections.scss';

export default function Collections({setView, subView, setSubView, isOffline, isIOS, updatesWaiting}) {

  const SubView = ({ type }) => {
    switch (type.view) {
      case "products":
        return <Products subView={subView} setSubView={setSubView} isOffline={isOffline} isIOS={isIOS} updatesWaiting={updatesWaiting} />
      case "product":
          return <Product subView={subView} setSubView={setSubView} isOffline={isOffline} isIOS={isIOS} updatesWaiting={updatesWaiting} />
      case "collection":
          return <Collection subView={subView} setSubView={setSubView} isOffline={isOffline} isIOS={isIOS} updatesWaiting={updatesWaiting} />
      case "collections":
          return <AllCollections subView={subView} setSubView={setSubView} isOffline={isOffline} isIOS={isIOS} updatesWaiting={updatesWaiting} />
      default:
        return <Brands subView={subView} setSubView={setSubView} isOffline={isOffline} isIOS={isIOS} />
    }
  }

  return (
    <Box className='app-page collections' >
        <SubView type={subView} />
    </Box> 
  )
}
