import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {Remove, Edit, Delete, Add} from '../../utils/icons';
import '../../styles/Profile.scss';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import NewSubscription from './NewSubscription'

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: 'none',
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', transform: 'rotate(-90deg)' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    border: 'none',
    backgroundColor: 'transparent',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
    paddingLeft: '0px',
    paddingRight: '0px'
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: 'none',
  }));

export default function SavedCollections({props}) {
    
    const { children, value, index, items, store, removeClick, setOpen, open, db, setSaved, saved, setView, subView, setSubView, ...other } = props;

    const [expanded, setExpanded] = React.useState(store === 'saved' && items[0] && items[0].products.length > 0 ? 'panel0' : '');

    const [selected, setSelected] = React.useState({});
    
    useEffect(() => {
        if (Object.keys(selected).length > 0) {
            setOpen('edit')
        }
        else {
            setOpen('')
        }    
    
    }, [selected]);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const deleteClick = async (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        
        await db.delete('saved', id);
    
        let updateList;
    
        updateList = [...saved]
        let updatedRemoved = updateList.filter(f => f.id !== id)
        setSaved(updatedRemoved)
      }

      const editClick = async (e, id) => {
        e.preventDefault();
        e.stopPropagation();

        setSelected(saved.find(f => f.id === id))

      }

    return (
        <Box>
            {store === 'saved' && items.length > 0 && 
                items.map((item, i) => {
                    return (
                        <Accordion key={`${item.name}_${i}`} expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)}>
                            <AccordionSummary aria-controls={`panel${i}d-content`} id={`panel${i}d-header`}>
                                <Grid key={item.id} container direction="row" alignItems={'center'} justifyContent={'space-between'}>
                                    <Grid item>
                                        <p style={{fontSize: '16px', lineHeight: '20.51px', textAlign: 'left'}}>{item.name}</p>
                                        <p style={{fontSize: '13px', lineHeight: '16.67px', color: '#757575', textAlign: 'left'}}>Contains {item.products.length} items</p>
                                    </Grid>
                                    <Grid item>
                                        <Grid key={`${item.id}-actions`} container direction="row" alignItems={'center'}>
                                            <Grid item>
                                                <Button className='collection-grid-btn action' onClick={(e) => editClick(e, item.id)} aria-label={`Edit collection ${item.name}`}><Edit /></Button>
                                            </Grid>
                                            <Grid item>
                                                <Button className='collection-grid-btn action' onClick={(e) => deleteClick(e, item.id)} aria-label={`Remove collection ${item.name}`}><Delete /></Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                {item.products && item.products.length > 0 &&
                                    item.products.map((p, i) => {
                                        return (
                                            <Grid key={i.id} container direction="row" alignItems={'center'} justifyContent="space-between" className='profile-grid-container'>
                                                <Grid item>
                                                    <Grid key={p.id} container direction="row" alignItems={'center'}>
                                                        <Grid item>
                                                            <Button className='collection-grid-btn profile' onClick={(e) => {
                                                                setSubView({view: 'product', id: p.id, name: p.name, last: subView})
                                                                setView('collections')
                                                            }}>
                                                                <img className='profile-image' src={p.image_data_url && p.image_data_url ? p.image_data_url : p.image} alt="" width={'100%'} />
                                                            </Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <p style={{ marginLeft: '12px'}}>{p.name}</p>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item textAlign={'right'}>
                                                    <Button className='collection-grid-btn' onClick={(e) => removeClick(e, p.id, 'saved', item.id)} aria-label={`Remove item ${p.name} from ${store} collection ${item.name}`}><Remove /></Button>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }
            <Box>
                <Button onClick={(e) => setOpen('new')} variant='outlined' className='product addToCollection' style={{padding: '9px 26px 8px'}} aria-label="start new collections"><Add /> <span style={{paddingTop: '1px'}}>START NEW COLLECTION</span></Button>
                <NewSubscription open={open} setOpen={setOpen} db={db} setSaved={setSaved} saved={saved} selected={selected} setSelected={setSelected} />
            </Box>
        </Box>
    )

}