import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Settings } from '../utils/icons';

export default function SettingsIcon({handleClickOpen, updatesWaiting}) {

  return (
    <Button
        className='settings-icon-btn'
        onClick={handleClickOpen}
        aria-label="Settings"
    >
        <Settings />
        {updatesWaiting && <div className='update-cirle'></div>}
    </Button>
  )
}
