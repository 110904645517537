import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CloseIcon from '../CloseIcon';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SuccessIcon from '../../images/checkmark.gif';
import NewSubscription from './NewSubscription'
import { Add } from '../../utils/icons';

export default function AddSubscription(props) {

    const {openAdd, setOpenAdd, db, setSaved, saved, selected, setSelected} = props

    const [name, setName] = React.useState('');
    const [checked, setChecked] = React.useState([])
    const [disabled, setDisabled] = React.useState([])
    const [openNew, setOpenNew] = React.useState(false)
    const [success, setSuccess] = React.useState(false)

    useEffect(() => {

        let initChecked = []

        saved.forEach(s => {
            if (s.products.findIndex(p => p.id === selected.id) > -1) {
                initChecked.push(s.id)
            }
        })

        setChecked(initChecked)
        setDisabled(initChecked)
        
       }, [openAdd]);

    const handleClose = () => {
        setChecked([])
        setDisabled([])
        setOpenAdd(false)
        setSelected({})
        setSuccess(false)
    }

    const handleChange = (e) => {
        const list = e.target.value
        let newList = [...checked]
        const isInList = newList.findIndex(c => c === list)
        
        if (isInList > -1) {
            newList.splice(isInList, 1)
        }
        else {
            newList.push(list)
            e.checked = true
        }

        setChecked(newList)
    }

    const collectionName = (e) => {
        setName(e.target.value)
    }

    const productAddClick = async (e) => {
        let tempList = [...saved]
        for (let i=0; i < checked.length; i++) {
            const getListIndex = tempList.findIndex(l => l.id === checked[i])
            const isSaved = tempList[getListIndex].products.findIndex(p => p === selected.id)
            if (getListIndex > -1 && isSaved === -1) {
                tempList[getListIndex].products.push({
                    id: selected.id,
                    name: selected.name,
                    image: selected.image_url,
                    image_data_url: selected.image_data_url
                })
                const getList = tempList[getListIndex]
                await db.put('saved', tempList[getListIndex]);
                
            }
        }
        setSaved(tempList)
        setSuccess(true)
      }

      const productNewClick = async (e) => {
        setOpenNew(true)
      }

  return (
    <Dialog
        open={openAdd}
        onClose={handleClose}
        className='settingsDialog'
        style={{
            backgroundColor: 'transparent',
            overflowY: 'scroll',
          }}
      >
            
              <CloseIcon handleClose={handleClose} label="Close add to collection" />
            {Object.keys(selected).length > 0 && !openNew && !success &&
                <Box className="settingsDialogContent" style={{paddingTop: '7px', paddingBottom: '50px'}}>
                <h2>Add to Collection</h2>
                <Box className='profile-grid-container'>
                    <Grid key={'prod_img'} container direction="row" alignItems={'center'} marginTop={'41px'} justifyContent={'center'}>
                        <Grid className='profile-image-wrapper' item><img src={selected.image_data_url && selected.image_data_url ? selected.image_data_url : selected.image_url} alt="" /></Grid>
                        <Grid item>
                            <p style={{
                                fontSize: '18px',
                                lineHeight: '23px',
                                color: '#000000',
                                fontWeight: '600',
                                marginBottom: '0',
                                marginLeft: '12px'
                            }}>{selected.name}</p>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
               {saved && saved.length > 0 &&
                 <FormGroup>
                    {saved.map(s => {
                        const isSelected = checked.find(p => p === s.id)
                        const isDisabled = disabled.find(p => p === s.id)
                        return (
                            <FormControlLabel control={<Checkbox value={s.id} onChange={handleChange} checked={isSelected ? true : false} disabled={isDisabled ? true : false} />} label={s.name} />
                        )
                    })}
                </FormGroup>
               }
                </Box>
                <Button onClick={productAddClick} variant='outlined' className='product' disabled={checked.length > 0 && checked.length > disabled.length ? false : true}>ADD TO COLLECTION(S)</Button>
                <Button onClick={productNewClick} variant='outlined' className='product addToCollection' style={{padding: '9px 26px 8px'}}><Add /> <span style={{paddingTop: '1px'}}>START NEW COLLECTION</span></Button>
            </Box>
            }
            {openNew && !success &&
                <NewSubscription openNew={openNew} setOpenNew={setOpenNew} setOpenAdd={setOpenAdd} db={db} setSaved={setSaved} saved={saved} selected={selected} setSelected={setSelected} />
            }
            {success && 
                <Box className="settingsDialogContent" style={{paddingTop: '7px', paddingBottom: '50px'}}>
                <Box style={{textAlign: "center", marginTop: '20px'}}>
                    <img src={SuccessIcon} alt='' style={{width: '125px', height: '125px'}} />
                </Box>
                <h2 style={{marginTop: '0px'}}>Item Added!</h2>
            </Box>
            }
      </Dialog>
  )
}
