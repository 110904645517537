import React, { useState, useEffect } from 'react';
import { openDB } from 'idb';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import PageHeader from '../components/PageHeader';
import {Search as SearchIcon} from '../utils/icons';
import '../styles/Collections.scss';
import { toDataURL } from '../utils/offline-image';

export default function Search({setView, subView, setSubView, updatesWaiting, isOffline}) {

  const [searchTerm, setSearchTerm] = useState([])
  const [results, setResults] = useState([])
  const [products, setProducts] = useState([])

  const getCollections = async () => {
    const getDB = await openDB(process.env.REACT_APP_INDB_NAME)
    
    const getProducts = await getDB.getAllFromIndex('products', 'id')

    setProducts(getProducts)
  }

  useEffect(() => {
   getCollections()
  }, []);

  const handleSearch = async (e) => {
    const term = e.target.value.toLowerCase()
    console.log('term', term)
    if (term.length >= 3) {
        let newResults = products.filter(p => {
            
            if (p.description.toLowerCase().indexOf(term) > -1 ) {
                return true
            }

            if (p.name.toLowerCase().indexOf(term) > -1 ) {
                return true
            }

            const inOpts = p.variants.edges.filter(v => v.node.productOptions.edges[0].node.values.edges[0].node.label.toLowerCase().indexOf(term) > -1)
                
            if (inOpts.length > 0) {
                return true
            }

            return false
            
        })
        console.log('results', newResults)

        if (isOffline) {
            for (let i=0; i < newResults.length; i++) {
                newResults[i].image_data_url = await toDataURL(newResults[i].image_url)
            }
        }

        setResults(newResults)
    }
  }

  return (
    <Box className='app-page search'>
        <Box className='app-sub-page'>
            <PageHeader title={'Search'} updatesWaiting={updatesWaiting} />
            <Box className='search-box'>
            <Box className='search-input-wrapper' sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <SearchIcon />
                <input className='search-input' disabled={products.length > 0 ? false : true} type="text" placeholder="Search products or categories..." onChange={(e) => handleSearch(e)} />
            </Box>
            </Box>
            <Box className='collection-grid'>
                {results.length > 0 && 
                    <h3>Results</h3>
                }
                {products.length <= 0 && 
                    <p style={{marginTop: "16px", textAlign: "left"}}>Product data downloading. If message persists after a few minutes, try updating from settings page.</p>
                }
                <Grid container direction="row" className='collection-grid-container'>
                    {results.length > 0 &&
                        results.map((c) => {
                            let imgSrc = c.image_url
                            if (c.image_data_url) {
                                imgSrc = c.image_data_url;
                            }
                            return (
                                <Grid key={c.id} item className="collection-grid-item">
                                    <Button className='collection-grid-btn' onClick={(e) => {
                                        setSubView({view: 'product', id: c.id, name: c.name, last: subView})
                                        setView('collections')
                                    }}>
                                        <img className='collection-grid-image' src={imgSrc} alt={c.name} />
                                    </Button>
                                    <p>{c.name}</p>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Box>
        </Box>
    </Box>
  )
}
